'use client';

import { Box, styled } from '@mui/material';

const DIALOG_MAX_WIDTH = 440;

export const Root = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexFlow: 'column nowrap',
	justifyContent: 'center',
	alignItems: 'center',
	background: theme.palette.custom.component.overlay,
}));

export const ContentWrapper = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	margin: 'auto',
}));
export const Main = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: theme.typography.pxToRem(DIALOG_MAX_WIDTH),
	background: theme.palette.custom.light.highest,
	padding: theme.spacing(5, 5, 5),
	borderRadius: theme.typography.pxToRem(24),
}));

export const Header = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2.5, 2.5, 2.5),
}));
