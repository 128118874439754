'use client';

import { Logo } from '@shared/components/logo';

import { Header, Main, Root, ContentWrapper } from './styles';

interface TMinimalisticLayoutProps {
	outOfBorderElement?: React.ReactNode;
}
export const MinimalisticLayout: React.FC<React.PropsWithChildren<TMinimalisticLayoutProps>> = ({
	children,
	outOfBorderElement,
}) => (
	<Root>
		<Header>
			<Logo />
		</Header>
		<ContentWrapper>
			<Main>{children}</Main>
			{outOfBorderElement}
		</ContentWrapper>
	</Root>
);
